import firebase from './index';

firebase.analytics();

const analyticsEvent = (event, param) =>{
return firebase.analytics().logEvent(event,param);
};

export {analyticsEvent};

