import React from "react";
import Data from "../data/Data";

const Plan = () => {
  const { plan } = Data;

  const plans = plan.map((item, idx) => (
    <div
      key={idx}
      data-content={item.data}
      className={`tab-content bg-white mb-4 mb-lg-0 w-c-25 mx-2 rounded border wrap pt-5 pb-4 px-4 ${
        idx === 2 && "active border-red shadow my-lg-n3"
      }`}
    >
      <div className="content">
        <div className="display-5 text-lightblue mb-1">
          <i className={item.icon}></i>
        </div>

        {idx === 2 && (
          <p className="text-red text-uppercase mb-1 font-weight-extrabold small">
            Más vendido
          </p>
        )}

        <h3 className="text-uppercase h6 font-weight-bold">{item.title}</h3>

        <div
          className={`h3 font-weight-extrabold ${
            idx === 2 ? "text-red" : "text-blue"
          }`}
        >
          {
            <div>
            {idx === 0 && (
              "Desde "
            )}
            S/ {item.price}
              <span className="h3 font-weight-bold mb-0 ml-2"> mensual</span>
            </div>
          }
        </div>

        {item.activate !== "" ? (
            idx === 0 ? (
              <p className="text-secondary">
                Ilimitado (∞) <br/> S/ {item.activate}
              </p>
            ) : (
              <p className="text-secondary">
                Costo de activación <br/> S/ {item.activate}
              </p>
            )
        ) : (
          <br />
        )}

        <p className="text-secondary">
          Precios no incluyen IGV
        </p>

        <p className="mb-4 pb-2">{item.description}</p>

        <ul className="list-unstyled mb-0 text-left list">
          {item.featured.map((list, i) => (
            <li key={i}>{i === 0 ? <strong>{list}</strong> : list}</li>
          ))}
        </ul>
      </div>

      <div className="footer">
        <p className="mb-4">
          <a href="/precios" className="text-primary text-decoration-underline">
            Ver detalle
          </a>
        </p>
        <p>
          <a
            href={item.link}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-auto btn-block btn-outline-primary"
          >
            Contratar
          </a>
        </p>
      </div>
    </div>
  ));

  return <div className="tab-wrapper d-lg-flex text-center">{plans}</div>;
};

export default Plan;
