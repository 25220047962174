import React, {useState} from "react";
import {useInput} from "../hooks/UseInput";
import axios from "axios";
import environment from "../environment/environment";

const Form = (props) => {

  const {value: name, bind: bindName, reset: resetName} = useInput('');
  const {value: email, bind: bindEmail, reset: resetEmail} = useInput('');
  const {value: phone, bind: bindPhone, reset: resetPhone} = useInput('');
  const {value: ciu, bind: bindCiu, reset: resetCiu} = useInput('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const sendForm = (evt) => {
    evt.preventDefault();
    setLoading(true);

    let data = {name, email, ciu, phone, campaign: props.title === "INDEX" ? 'web' : 'web-partners', companiesId: 1};

    const validateUrl = `${environment.apiUrl}extranet/auth/new`;
    axios.post(validateUrl, data)
        .then(function (response) {
          setLoading(false);
          setSuccess(true);
          closeAlerts();
        })
        .catch(function (error) {
          setLoading(false);
          setError(true);
          closeAlerts();
        });
  };

  const closeAlerts = () => {
    resetName();
    resetEmail();
    resetCiu();
    resetPhone();
    document.getElementById('accept').click();
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 4000);
  };

  return (
    <div className="bg-white p-4 p-lg-5 rounded shadow">
      <div className="p-2 p-lg-0">
        {props.title === "INDEX" ?
            <h2 className="mb-4 font-weight-extrabold text-blue">Te Asesoramos GRATIS</h2>
            :
            <h2 className="mb-4 font-weight-extrabold text-blue">Contáctenos</h2>
        }
        <form id="form" method="post" onSubmit={sendForm}>
          <div className="form-group">
            <label htmlFor="form-name">Nombre y apellidos: *</label>
            <input type="text" id="form-name" className="form-control" {...bindName} required />
          </div>
          <div className="form-group">
            <label htmlFor="form-email">Correo electrónico: *</label>
            <input type="email" id="form-email" className="form-control" {...bindEmail} required />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="form-phone">Número de celular: *</label>
                <input type="text" id="form-phone" className="form-control" pattern="[9-9]{1}[0-9]{8}" {...bindPhone} required />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="form-ciu">Número de RUC: *</label>
                <input type="text" id="form-ciu" className="form-control" pattern="[1-2]{1}[0-0]{1}[0-9]{9}" {...bindCiu} required />
              </div>
            </div>
          </div>
          <div className="form-group small">
            <label htmlFor="accept">
              <input type="checkbox" name="" id="accept" className="mr-2" required />
              He leído y acepto la{" "}
              <a href="/#" target="_blank" className="text-primary">
                Política de Privacidad
              </a>{" "}
              y los{" "}
              <a href="/#" target="_blank" className="text-primary">
                Términos y Condiciones
              </a>
              .
            </label>
          </div>
          <div className="form-group mb-0">
            {
              !loading && !success && !error ?
                <input
                    type="submit"
                    className="btn btn-lg btn-primary btn-block"
                    value="Enviar"
                /> :
                null
            }
            {
              loading && !success && !error ?
                <div className="btn btn-lg btn-primary btn-block">
                  <span style={{width: '25px', height: '25px'}} className="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"/>
                  <span>&nbsp;&nbsp;Enviando...</span>
                </div> :
                null
            }
            {
              success ?
                  <div id="send-ok" className="alert alert-success form-center" role="alert">
                    <strong>Correcto, información enviada...</strong>
                  </div> : null
            }

            {
              error ?
                  <div id="send-error" className="alert alert-danger form-center" role="alert">
                    <strong>Lo sentimos, ocurrió un error inesperado...</strong>
                  </div> : null
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
