export class ReCaptcha {
  index = 'grecaptcha';
  GCaptcha = window[this.index];
  getToken() {
    return this.GCaptcha.execute('6Lc-Gq0UAAAAAGuoWcwEcs95chkz96xmy8jHwjxN').then((res) => {
      sessionStorage.setItem('g-recaptcha-response', res);
      return res;
    });
  }
}
