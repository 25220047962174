import React from "react";
import Layout from "../components/Layout";
import FormConsultCpe from "../components/FormConsultCpe";

import Head from "../components/Head";
import { SeoConsultCpe } from "./../data/SEO";

const PageConsultCpe = () => {
  return (
    <Layout main>
      <Head
        Title={SeoConsultCpe[0].title}
        Description={SeoConsultCpe[0].description}
        isSiteName={true}
      />

      <section id="home" className="home py-5">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-5 mb-lg-0">
              <h1 className="display-4 mb-1 font-weight-extrabold text-blue d-none d-lg-block">
               Consulta tu comprobante
              </h1>
              <div className="d-none d-lg-block">
                <div>
                  <img
                    src={"/assets/images/factura.png"}
                    alt=""
                    width="550"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <FormConsultCpe title="Consultar comprobante" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageConsultCpe;
