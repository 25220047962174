import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import PageIndex from "./pages/Index";
import PagePrices from "./pages/Prices";
import PagePartners from "./pages/Partners";
import PageConsultCpe from "./pages/ConsultCpe";
import Page404 from "./pages/error404";
import queryString from 'query-string';

import firebase from './services/firebase';
import {analyticsEvent} from './services/firebase/analytics'

const RoutesListener = () => {
  const location = useLocation();

  useEffect(() => {
    firebase.analytics().setCurrentScreen(location.pathname);
    let param = queryString.parse(location.search);

    Object.keys(param).forEach(key=> {
      let value=param[key]

      if (!localStorage.getItem(key) || localStorage.getItem(key) !== value) {
        localStorage.setItem(key, value);
        analyticsEvent(key,{ value })
      }
    })

  }, [location]);

  return <></>;
};

const Routes = () => {
  return (
    <>
    <RoutesListener />
    <Switch>
      <Route exact path="/" component={PageIndex} />
      <Route path="/precios" component={PagePrices} />
      <Route path="/partners" component={PagePartners} />
        <Route path="/consultar_cpe" component={PageConsultCpe} />
      <Route path="*" component={Page404} />
    </Switch>
    </>
  );
};

export default Routes;
