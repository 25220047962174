const Year = new Date().getFullYear();

const Config = {
  siteName: "Emite",
  siteLogo: "/assets/images/logo.svg",
  siteLogoFooter: "/assets/images/logo-white.svg",
  siteCopyright: `© ${Year} Emite.pe`,
  siteLegal: "Todos los derechos reservados.",
  siteSlogan: "Soluciones en facturación electrónica",
  numberWhatsapp: "949534411",
  numberPhone1: "(+51) 01 744-7577",
  numberPhone2: "(+51) 01 744-7626",
  siteEmail: "ventas@doous.com",
  siteLocation: "Av. Nicolás Arriola 314 Of 502, Santa Catalina, Lima - Perú",
  siteDescription: "La mejor solución en facturación electrónica",
  supportDoous: "Con el respaldo de:  ",
  siteNameDoous: "Doous",
  siteLogoDoousFooter: "/assets/images/doous-white.png",
  plan: [
    {
      data: "facturador",
      icon: "icon-facturador",
      title: "Facturador",
      description: "Realiza facturas y boletas electrónicas de forma automática",
      featured: ["Usuario 1", "Comprobantes 100", "Facturación"],
      price: "29.90",
      link: "http://emite.pe/1",
      activate: "54.90",
    },
    {
      data: "emprende",
      icon: "icon-emprende",
      title: "Smart Emprende",
      description: "Realiza facturas y boletas electrónicas de forma automática",
      featured: ["Usuarios 2", "Comprobantes (∞)", "Facturación", "POS (1 caja)", "Reportes"],
      price: "74.90",
      link: "http://emite.pe/2",
      activate: "499.00",
    },
    {
      data: "despega",
      icon: "icon-despega",
      title: "Smart Despega",
      description: "Realiza facturas y boletas electrónicas de forma automática",
      featured: ["Usuarios 3", "Comprobantes (∞)", "Facturación", "POS (1 caja)", "Reportes", "Cobranzas"],
      price: "99.90",
      link: "http://emite.pe/3",
      activate: "999.00",
    },
    {
      data: "vuela",
      icon: "icon-vuela",
      title: "Smart Vuela",
      description: "Realiza facturas y boletas electrónicas de forma automática",
      featured: ["Usuarios 4", "Comprobantes (∞)", "Facturación", "POS (2 cajas)", "Reportes", "Cobranzas", "Compras", "Inventarios"],
      price: "149.90",
      link: "http://emite.pe/4",
      activate: "1499.00",
    },
  ],
  shareLinks: [
    {
      url: "https://www.facebook.com/emite.pe",
      iconClassName: "icon-facebook",
      bgClassName: "bg-facebook",
    },
    {
      url: "/#",
      iconClassName: "icon-twitter",
      bgClassName: "bg-twitter",
    },
    {
      url: "/#",
      iconClassName: "icon-instagram",
      bgClassName: "bg-instagram",
    },
    {
      url: "/#",
      iconClassName: "icon-youtube",
      bgClassName: "bg-youtube",
    },
    {
      url: "/#",
      iconClassName: "icon-pinterest",
      bgClassName: "bg-pinterest",
    },
  ],
};

module.exports = Config;
