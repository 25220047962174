const URLS_MATER = {
  emite_api: "https://portal.emite.pe/api/index.php"
}

const FIREBASE = {
  apiKey: "AIzaSyAles6Bffpgo_NvsJasAYJedkaOzQCFJJo",
  authDomain: "emite-pe.firebaseapp.com",
  projectId: "emite-pe",
  storageBucket: "emite-pe.appspot.com",
  messagingSenderId: "653027958938",
  appId: "1:653027958938:web:870c64754bd8880dfc7f23",
  measurementId: "G-YS01P84XJS"
}

export { URLS_MATER, FIREBASE };
