import React, {useState} from "react";
import { useInput } from "../hooks/UseInput";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { startOfDay } from 'date-fns';
import { Document } from '../data/Document';
import { ReCaptcha } from '../data/ReCaptcha';
import { analyticsEvent } from "../services/firebase/analytics";

registerLocale('es', es);

const FormConsultCpe = (props) => {
  const captchaService = new ReCaptcha();
  const { title } = props;
  const extensions = ['pdf', 'xml'];

  const documentTypes = [
    { id: '01', name: 'Factura', design: true },
    { id: '03', name: 'Boleta', design: true },
    { id: '07', name: 'Nota de Crédito', design: true },
    { id: '08', name: 'Nota de Débito', design: true },
    { id: '20', name: 'Retención', design: true },
    /*{ id: "RC", name: "Resumen Diario", design: false },*/
    /*{ id: "RA", name: "Comunicación de Baja", design: false },*/
    { id: '09', name: 'Guías', design: true }
  ];

  const {value: step, setValue: setStep} = useInput(1);
  const {value: docType, bind: bindDocType} = useInput('01');
  const {value: ciu, bind: bindCiu} = useInput('');
  const {value: series, bind: bindSeries} = useInput('');
  const {value: consecutive, bind: bindConsecutive} = useInput('');
  const {value: date, setValue: setDate} = useInput(new Date());
  const {value: total, bind: bindTotal, setValue: setTotal} = useInput('');
  const [needValidation, setNeedValidation] = useState(false);
  const [unProcessed, setUnProcessed] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [timeout, setTimeout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [downloadedPDF, setDownloadedPDF] = useState(false);
  const [downloadingXML, setDownloadingXML] = useState(false);
  const [downloadedXML, setDownloadedXML] = useState(false);

  const patternCiu = new RegExp('[1-2][0-9]{10}');
  const patternSeries = new RegExp('[A-Z0-9]{4}');
  const patternConsecutive = new RegExp('[0-9]{1,8}');
  const patternTotal = new RegExp('[0-9]{1,10}[.-.]{1}[0-9]{2}');

  let doc = new Document();

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleSelectChange = (evt) => {
    if (docType === '09') {
      setTotal('0.00');
    } else {
      setTotal('');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    analyticsEvent('action_find_cpe')
    setLoading(true);
    if (validate()) {
      setNeedValidation(false);
      const params = {
        docType,
        ciu,
        series,
        consecutive: parseInt(consecutive),
        date,
        total,
      };
      params.date = startOfDay(params.date).getTime();
      params.token = sessionStorage.getItem('g-recaptcha-response');
      const documentPromise = doc.getDocument(params);

      documentPromise
        .then(res => {
          analyticsEvent('res_find_cpe_ok')
          nextStep(res)
        })
        .catch(err => {
          analyticsEvent('res_find_cpe_err')
          if (err && err.response && err.response.status) {
            analyticsEvent('res_find_cpe_' + err.response.status)
            if (err.response.status === 408) {
              captchaService.getToken().then((token) => {
                params.token = token;
                const documentPromise2 = doc.getDocument(params);
                documentPromise2.then(res => nextStep(res));
              });
            }
          }
        });
    } else {
      setLoading(false);
      setNeedValidation(true);
    }
  };

  const nextStep = (res) => {
    setLoading(false);
    captchaService.getToken().then(r => {});
    if (!res) {
      setNotFound(true);
    } else {
      setNotFound(false);
      if (res.idstatedocument <= 1) {
        setUnProcessed(true);
      } else {
        setUnProcessed(false);
        // this.setState({ currentDocument: res });
        setStep(2)
      }
    }
  };

  const goBack = () => {
    captchaService.getToken().then(r => {});
    setStep(1);
    setLoading(false);
    setTimeout(false);
    setNotFound(false);
    setUnProcessed(false);
    setDownloadingPDF(false);
    setDownloadingXML(false);
    setDownloadedPDF(false);
    setDownloadedXML(false);
  };

  const validate = () => {
    if (!series || !patternSeries.test(series)) {
      return false;
    }
    if (!ciu || !patternCiu.test(ciu)) {
      return false;
    }
    if (!consecutive || !patternConsecutive.test(consecutive)) {
      return false;
    }
    if (!date) {
      return false;
    }
    if (!total || !patternTotal.test(total)) {
      return false;
    }
    return true;
  };

  const downloadPDF = () => {
    download('pdf');
  };

  const downloadXML = () => {
    download('xml');
  };

  const download = (type) => {
    if (loading) {
      return;
    }
    analyticsEvent('download_cpe_' + type);
    if (type === 'pdf') {
      setDownloadingPDF(true);
    } else {
      setDownloadingXML(true);
    }
    const documentPromise = doc.downloadFile(ciu, docType, series, consecutive, type, cb);

    documentPromise.then(r => {
      setDownloadingPDF(false);
      setDownloadingXML(false);
      if (type === 'pdf') {
        setDownloadedPDF(true);
      } else {
        setDownloadedXML(true);
      }
      regenerateToken();
    });
  };

  const cb = () => {
    setTimeout(true);
  };

  const regenerateToken = () => {
    if (!(downloadedPDF && downloadedXML)) {
      setLoading(true);
      captchaService.getToken().then((token) => {
        setLoading(false);
      });
    }
  };

  return (
    step === 1 ?
      <div className="bg-white p-4 p-lg-5 rounded shadow">
        <div className="p-2 p-lg-0">
          <h2 className="mb-4 font-weight-extrabold text-blue">{title}</h2>
          <form action="" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="form-name">Tipo de documento: *</label>
                  <select id="docType" onClick={handleSelectChange} className="form-control" {...bindDocType}>
                    {documentTypes.map((i, key) => (
                        <option key={key.toString()} value={i.id}>
                          {i.name}
                        </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="form-name">RUC del emisor: *</label>
                  <input
                      pattern="[1-2]{1}[0-9]{10}"
                      type="text"
                      className="form-control"
                      id="ciu"
                      aria-describedby="emailHelp"
                      placeholder="RUC"
                      {...bindCiu}
                      required
                  />
                  { needValidation ? <div className="invalid-feedback">Necesita ingresar un ruc</div> : '' }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="form-name">N° Serie: *</label>
                  <input
                      pattern="[A-Z0-9]{4}"
                      type="text"
                      className="form-control"
                      id="series"
                      aria-describedby="emailHelp"
                      placeholder="Serie"
                      {...bindSeries}
                      required
                  />
                  { needValidation ? <div className="invalid-feedback">Necesita ingresar una serie</div>: '' }
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="form-name">N° Correlativo: *</label>
                  <input
                      pattern="[0-9]{1,8}"
                      type="text"
                      className="form-control"
                      id="consecutive"
                      aria-describedby="emailHelp"
                      placeholder="Correlativo"
                      {...bindConsecutive}
                      required
                  />
                  { needValidation ? <div className="invalid-feedback">Necesita ingresar un correlativo</div>: '' }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="form-name">Fecha de emisión: *</label>
                  <DatePicker
                      id="date"
                      className="form-control"
                      selected={date}
                      onChange={handleChangeDate}
                      locale="es"
                      dateFormat="dd/MM/yyyy"
                      required
                  />
                  { needValidation ? <div className="invalid-feedback">Necesita ingresar una fecha</div>: '' }
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="form-name">Monto total: *</label>
                  <input
                      pattern="[0-9]{1,10}[.-.]{1}[0-9]{2}"
                      type="text"
                      className="form-control"
                      id="total"
                      aria-describedby="emailHelp"
                      placeholder="Monto (0.00)"
                      {...bindTotal}
                      required
                  />
                  { needValidation ? <div className="invalid-feedback">Necesita ingresar un total</div>: '' }
                </div>
              </div>
            </div>
            {unProcessed ? (
                <div className="alert alert-warning" role="alert">
                  Su comprobante se encuentra en nuestros sistemas pero aun no ha sido validado
                </div>
            ) : null}
            {notFound ? (
                <div className="alert alert-info" role="alert">
                  No se encontraron documentos, asegurese que ingreso la información correcta
                </div>
            ) : null}
            {timeout ? (
                <div className="alert alert-info" role="alert">
                  Tiempo de espera agotado
                </div>
            ) : null}
            <div className="form-group mb-0 mt-4">
              {
                loading ?
                  <div className="btn btn-lg btn-primary btn-block">
                  <span style={{width: '25px', height: '25px'}} className="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"/>
                        <span>&nbsp;&nbsp;Consultando</span>
                  </div>
                :
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary btn-block"
                    value="Consultar"
                  />
              }
            </div>
          </form>
        </div>
      </div>
    :
      <div className="bg-white p-4 p-lg-5 rounded shadow">
        <div className="p-2 p-lg-0">
          <h2 className="mb-4 font-weight-extrabold text-blue">{title}</h2>

          <ul className="list-group">
            {extensions.map(ext => (
              <li key={ext} className="list-group-item">
                <div className="row mt-3 mb-1">
                  <div className="col-md-10">
                    <div className="d-inline-flex">
                      <h5 className="d-inline-flex card-title inline-text">
                        Archivo {ext.toUpperCase()} de {series}-{consecutive}
                      </h5>
                    </div>
                    <p className="card-text font-italic text-muted">
                      <small>{
                        ext === 'pdf' ?
                          downloadedPDF ? 'Archivo descargado' : 'Haga click en el icono de la derecha para descargar.'
                        :
                          downloadedXML ? 'Archivo descargado' : 'Haga click en el icono de la derecha para descargar.'
                      }</small>
                    </p>
                  </div>
                  <div className="col-md-2 m-auto imageDownload">
                    {ext === 'pdf' ?
                        downloadedPDF ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" fill="currentColor"
                               className="bi bi-check2" viewBox="0 0 16 16">
                            <path
                                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          </svg>
                        :
                          downloadingPDF ?
                            <span style = {{width: '25px', height: '25px'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                          :
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-file-earmark-text" viewBox="0 0 16 16" onClick={downloadPDF}>
                              <path
                                  d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                              <path
                                  d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                    :
                        downloadedXML ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" fill="currentColor"
                               className="bi bi-check2" viewBox="0 0 16 16">
                            <path
                                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          </svg>
                        :
                          downloadingXML ?
                            <span style = {{width: '25px', height: '25px'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                          :
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-file-code" viewBox="0 0 16 16" onClick={downloadXML}>
                              <path
                                  d="M6.646 5.646a.5.5 0 1 1 .708.708L5.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0a.5.5 0 1 0-.708.708L10.293 8 8.646 9.646a.5.5 0 0 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z"/>
                              <path
                                  d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                            </svg>
                    }
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="form-group mb-0 mt-4">
            {
              loading ?
                <div className="btn btn-lg btn-primary btn-block">
                  <span style={{width: '25px', height: '25px'}} className="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"/>
                  <span>&nbsp;&nbsp;Preparando</span>
                </div>
              :
                <input
                    type="button"
                    onClick={goBack}
                    className="btn btn-lg btn-primary btn-block"
                    value="Volver"
                />
            }
          </div>

        </div>
      </div>
  );
};

export default FormConsultCpe;
