import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
import Config from "./../data/Data";

class Menu extends Component {
  handleClose = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const resp = document.querySelector(".responsive");

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");

    resp.classList.remove("active");
    resp.style.height = "100%";

    setTimeout(function () {
      resp.style.height = "0%";
    }, 0);

    document.querySelector(".menu-close").classList.remove("active");
    document.querySelector(".menu-icon").classList.add("active");
    document.querySelector(".menu-shadow").classList.remove("active");
  };

  handleClick = () => {
    const resp = document.querySelector(".responsive");

    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
    resp.classList.add("active");
    resp.style.height = "0%";

    setTimeout(function () {
      resp.style.height = "100%";
    }, 0);

    document.querySelector(".menu-icon").classList.remove("active");
    document.querySelector(".menu-close").classList.add("active");
    document.querySelector(".menu-shadow").classList.add("active");
  };

  render() {
    const { siteLogo, siteName, siteSlogan } = Config;

    return (
      <Fragment>
        <div className="h3 mb-0 d-lg-none menu mt-3">
          <span className="active menu-icon" onClick={this.handleClick}>
            <i className="icon-menu active"></i>
          </span>
        </div>

        <div className="responsive">
          <div className="d-flex d-lg-none p-3 align-items-center border-bottom w-100 justify-content-between">
            <div>
              <a href="/">
                <img
                  src={siteLogo}
                  alt={`${siteName} - ${siteSlogan}`}
                  height="40"
                />
              </a>
            </div>
            <div className="pt-2 h4 mb-0">
              <span className="menu-close" onClick={this.handleClose}>
                <i className="icon-close"></i>
              </span>
            </div>
          </div>

          <div className="list-unstyled pt-2 pt-lg-0 h5 d-lg-flex align-items-center m-0">
            <div className="responsive-item">
              <NavLink
                to={`/`}
                exact
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Inicio
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                to={`/precios`}
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Precios
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                to={`/partners`}
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Partners
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                  to={`/consultar_cpe`}
                  className="btn btn-sm btn-secondary btn-auto"
                  activeClassName="active"
                  onClick={this.handleClose}
              >
                Consultar comprobantes
              </NavLink>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Menu;
