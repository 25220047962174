import axios from 'axios';
import { URLS_MATER } from '../Config';
import { analyticsEvent } from '../services/firebase/analytics';
// const api = process.env.REACT_APP_INTEGRATION_PORTAL_API;
const api = URLS_MATER.emite_api;
/*export interface DocumentEntity {
  series: string;
  ciu: string;
  consecutive: number;
  date: number;
  total: string;
  docType: string;
  token?: string | null;
}

export interface DocumentInfo {
  ciu: string;
  series: string;
  consecutive: number;
  create_date: number;
  idstatedocument: number;
}*/

export class Document {
  path = 'document';
  entityUrl = `${api}/${this.path}`;
  getDocument(entity) {
    return axios.post(`${this.entityUrl}/search`, entity).then((res) => {
      return res.data;
    });
  }
  downloadFile(ciu, docType, series, consecutive, type, cb) {
    axios.defaults.headers.common['Token'] = sessionStorage.getItem('g-recaptcha-response');
    return axios
      .get(`${this.entityUrl}/${ciu}/${docType}/${series}-${consecutive}/${type}`, {
        responseType: 'arraybuffer'
      })
      .then((response) => {
        analyticsEvent('res_download_cpe_' + type + '_ok')
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${series}-${consecutive}.${type.toLowerCase()}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        analyticsEvent('res_download_cpe_' + type + '_err')
        cb();
      });
  }
}
