import Data from "./../data/Data";

const { siteName, siteSlogan } = Data;

export const SeoIndex = [
  {
    title: `${siteName} - ${siteSlogan}`,
    description:
      "Soluciones tecnológicas, diseño y creación de aplicativos y portales web, intranets, ERPs, entre otros. Trabajamos de forma ágil para crear soluciones escalables.",
  },
];

export const SeoPrices = [
  {
    title: "Tabla comparativa de precios",
    description:
      "Revisa nuestros planes, compara las características y elige el plan que más se acomodo a tu negocio",
  },
];

export const SeoPartners = [
  {
    title: "Partners",
    description:
      "Tenemos más de 5 años de experiencia en Facturación Electrónica, Olvídate de los problemas con SUNAT y sus actualizaciones, nosotros nos encargamos de eso.",
  },
];

export const SeoConsultCpe = [
  {
    title: "Consultar CPE",
    description:
        "",
  },
];

export const SeoError = [
  {
    title: "Página no encontrada",
    description:
      "Error 404, Esto puede deberse porque la página no existe o la ruta ha sido cambiado por una nueva.",
  },
];
