import React from "react";
import Config from "./../data/Data";
import Menu from "../components/Menu";

const Header = (props) => {
  const { siteLogo, siteName, siteSlogan } = Config;

  return (
    <header className="py-3 py-lg-4">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="logo">
          <a href="/">
            <img
              src={siteLogo}
              alt={`${siteName} - ${siteSlogan}`}
              height="60"
            />
          </a>
        </div>

        <Menu />
      </div>
    </header>
  );
};

export default Header;
